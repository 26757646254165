.tile {
  align-items: center;
  border: 2px var(--black) solid;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 70px;
  margin-right: 6px;

  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  text-transform: uppercase;
  user-select: none;

  cursor: pointer;
  border-radius: 5%;
}

.fiveLetterTile {
  height: 55px;
  width: 55px;
}

.smallTile {
  height: 35px;
  width: 35px;
  font-size: 1rem;
  line-height: 1rem;
  border-width: 1px;
  margin-right: 6px;
}

.altered {
  background-color: var(--alteredBackground);
  color: var(--alteredColorText);
}

.altered.failed {
  background-color: var(--alteredFailedBackground);
}

.stale {
  background-color: lightgrey;
}

.selected {
  background-color: var(--selectedTileBackground);
  color: white;
}

.attempted {
  background-color: var(--alteredBackground);
  color: var(--attemptedColorText);
}
.completed {
  background-color: var(--completedTileBackground) !important;
  color: var(--attemptedColorText);
}

@keyframes grow {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.animateTile {
  animation-name: grow;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
}

.bouncingTile {
  animation: bounce 0.75s 1;
}

.bouncingTile:nth-child(1) {
  animation-delay: 20ms;
}

.bouncingTile:nth-child(2) {
  animation-delay: 40ms;
}

.bouncingTile:nth-child(3) {
  animation-delay: 60ms;
}

.bouncingTile:nth-child(4) {
  animation-delay: 80ms;
}

.bouncingTile:nth-child(5) {
  animation-delay: 100ms;
}
