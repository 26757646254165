.achievementsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 12px;
  width: 100%;
  font-variant: small-caps;
}

.achievementContainer {
  background-color: var(--achievementBackground);
  margin-bottom: 12px;
  width: 90%;
  padding: 12px 24px;
  display: flex;
  border-radius: 12px;
  justify-content: space-between;

  outline: 1px solid var(--achievementDescription);
  outline-offset: -6px;
}

.achievementContainerLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.achievementContainerRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.achievementTitle {
  font-size: X-LARGE;
  color: var(--achievementDescription);
}

.achievementDescription {
  font-size: 12px;
  color: var(--achievementDescription);
}

.achievementCompleted {
  background-color: var(--achievementBackgroundSuccess);
}

.achivevementModalActions {
  display: flex;
  justify-content: center;
}

.achievementLocked {
  background-color: var(--achievementBackgroundLocked) !important;
  outline: none;

  color: transparent !important;
  text-shadow: 0 0 8px #000;
}

.achievementLocked .achievementDescription,
.achievementLocked .achievementProgress,
.achievementLocked .achievementTitle {
  color: transparent !important;
  text-shadow: 0 0 8px #000;
}

.achievementProgress {
  color: var(--achievementDescription);
}

.clickable {
  cursor: pointer;
}

.achievementIcon {
  font-size: 20px !important;
  margin-right: 12px !important;
  color: var(--white);
}
