body {
  background-color: var(--white);
  color: var(--black);
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 600px;
  text-align: center;
  /* height: 100vh; */
  max-height: 1000px;

  height: var(--app-height);
}

.App-iOS {
  height: -webkit-fill-available;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hint {
  font-size: 1.5rem;
  margin-top: 12px;
  margin: 24px 0px;
  margin-top: 12px;
  padding: 0 12px;
  margin-bottom: 20px;
}

.bar {
  min-width: 0 !important;
}

.message {
  margin-top: 12px;
  color: white;
  position: fixed;
  bottom: 0px;
  margin-bottom: 205px;
  height: 50px;
  width: fit-content;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.slide-top {
  -webkit-animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-3-21 15:48:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }
  100% {
    -webkit-transform: translateY(90px);
    transform: translateY(90px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(70px);
    transform: translateY(70px);
  }
  100% {
    -webkit-transform: translateY(90px);
    transform: translateY(90px);
  }
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}

.bigMessage {
  font-size: xx-large;
  margin-bottom: 12px;
}

.resultsScore {
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 12px;
}

.resultsTime {
  font-size: x-large;
  margin-bottom: 12px;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
  height: 100%;
}

.menuButton {
  margin-bottom: 20px;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.headerSection {
  display: flex;
  height: 100%;
  align-items: center;
}

.hg-button {
  height: 60px !important;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

.button {
  letter-spacing: 0.2rem;
  text-transform: uppercase;
}

.howToPlayContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  letter-spacing: 0.2rem;
  width: 100%;
  padding: 0px 24px;
}

.howToPlayContainer h4:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid gray;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  letter-spacing: 0.2rem;
  width: 100%;
  padding: 0px 24px;
}

.aboutContainer h4 {
  margin-bottom: 0;
}

.aboutContainer div:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid gray;
}

.aboutRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

:root {
  --black: black;
  --white: white;
}

.hg-theme-default .hg-button {
  background-color: var(--white) !important;
  color: var(--black) !important;
  border-bottom: none !important;
  border: 1px solid var(--black) !important;
  border-radius: 0px !important;
  border-right: 0 !important;
  border-bottom: 0px !important;
}

.hg-theme-default .hg-button:last-child {
  border-right: 1px solid var(--black) !important;
}

.hg-theme-default .hg-button.hg-activeButton {
  background-color: var(--keyboardActiveBackground) !important;
}

.hg-row:last-child {
  border-bottom: 1px solid var(--black) !important;
}

.hg-theme-default {
  background-color: var(--keyboardBackground) !important;
  padding: 0px !important;
}

.hg-theme-default .hg-row:not(:last-child) {
  margin-bottom: 0px !important;
}

.hg-theme-default .hg-row .hg-button:not(:last-child) {
  margin-right: 0px !important;
}

.chart.button {
  margin-right: 12px;
}

.statsBlocks {
  display: flex;
  justify-content: center;
}

.statsBlock {
  margin: 24px;
}

.statsValue {
  font-size: x-large;
  font-weight: bold;
}

.statsTitle {
  font-size: small;
}

button:focus {
  outline: none;
}

.settingRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 12px;
}

.howToPlayContainer .settingRow:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid gray;
}

.settingName {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 6px;
  font-variant: small-caps;
}

.settingDescription {
  font-size: 12px;
  line-height: 1.5;
}

.settingWithDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding-right: 24px;
}

.statsContainer {
  background-color: var(--statsBackground);
  padding: 12px;
  border-radius: 10%;
  margin-top: 12px;
}

.archiveContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--statsBackground);
  padding: 24px;
  border-radius: 10px;
  margin: 0px 12px;
  margin-bottom: -30px;
  font-size: small;
}

.bannerResultsContainer {
  margin-bottom: 24px !important;
}

.ui.loader:after,
.ui.loader:before {
  height: 100px;
  width: 100px;
  margin-left: -50px;
}

#challengeModeTitle {
  font-variant: all-small-caps;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 12px;
}

#challengeModeDescription {
  margin: 24px;
  line-height: 1.6;
}

#contact {
  color: var(--black) !important;
  cursor: pointer;
  display: block;
  margin-top: 2px;
}

.headerButton {
  background-color: var(--headerBackgroundColor) !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 10% !important;
  display: flex !important;
  justify-content: center !important;
}

.footerButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  font-size: 20px !important;
}

.ui.modal {
  top: 35% !important;
}

.unseenAchievements {
  background-color: var(--alteredBackground) !important;
  color: var(--white) !important;
}

.cta {
  color: white;
  background-color: mediumpurple;
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-variant: all-petite-caps;
  border-radius: 12px;
  font-size: xx-large;
}

.outerWordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.answerHintLater {
  margin-left: 12px !important;
  cursor: pointer;
}

#showHintsButton {
  margin-bottom: 12px !important;
  font-variant: all-petite-caps;
  width: calc(100% - 6px) !important;
  font-size: 20px !important;
  box-shadow: 0 0 0 2px var(--black) inset !important;
  color: var(--black) !important;
}

#shareButton {
  animation-name: shareGrow;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes shareGrow {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animateHintTile {
  animation-name: hintTileGrow;
  animation-duration: 0.75s;
  animation-iteration-count: 1;
}

@keyframes hintTileGrow {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

#resultBoardContainer .altered {
  background-color: var(--completedTileBackground);
  color: var(--white);
}

#resultBoardContainer .altered.failed {
  background-color: var(--alteredBackground);
  color: var(--white);
}

@keyframes shake {
  10%,
  50%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  30%,
  70% {
    transform: translate3d(10px, 0, 0);
  }
}

.animateWrongWord {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  animation-duration: 0.6s;
}

.zenModeContainer {
  display: flex;
  flex-direction: column;
  background-color: #4c467a;
  color: white;
  width: 80%;
  margin: auto;
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
