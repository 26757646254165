.timerContainer {
  width: 100%;
  display: flex;
  align-items: center;
}

.progress {
  margin-bottom: 0 !important;
}

.progressBar {
  flex-grow: 1;
}
