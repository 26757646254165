.wordContainer {
  display: flex;
  margin-bottom: 6px;
}

/* .alert-enter {
  margin-left: -1000px;
}
.alert-enter-active {
  margin-left: 0;
  transition: margin-left 300ms;
} */
/* .alert-exit {
  margin-top: 0px;
}
.alert-exit-active {
  margin-top: 1000px;
  transition: margin-top 300ms;
} */

.animate__slideInUp {
  --animate-duration: 0.4s;
}

.hintBackground {
  background-color: var(--hintBackground);
  opacity: 90%;
  color: white !important;

  animation-name: grow2;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
}

@keyframes grow2 {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
